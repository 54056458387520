import moment from "moment"
import _ from "lodash"
import { getEvents } from "./EventsService"
import { CalendarEventT, CalendarMonthT, CalendarT } from "./CalendarTypes"

const toCalendarEvent = (item): CalendarEventT => {
    return {
        id: item.id,
        title: item.summary,
        description: item.description,
        date: item.start.dateTime || item.start.date,
        day: `${moment(item.start.dateTime || item.start.date).format("DD")}`,
        link: item.htmlLink,
    }
}

const groupEventsByYear = (events: CalendarEventT[]) => {
    const result = _.groupBy(events, (event) =>
        moment(event.date).format("YYYY")
    )
    return result
}

const groupEventsByMonth = (events: CalendarEventT[]) => {
    const result = _.groupBy(events, (event) =>
        moment(event.date).format("MMMM")
    )

    return result
}

const toCalendarMonths = (
    eventsInAYear: CalendarEventT[]
): CalendarMonthT[] => {
    const result = _.map(
        groupEventsByMonth(eventsInAYear),
        (_events, _month) => ({ month: _month, events: _events })
    )
    return result
}

export const getCalendar = async (): Promise<CalendarT> => {
    const rawEvents = await getEvents()

    const events = rawEvents.map((rawEvent) => toCalendarEvent(rawEvent))

    const eventsGroupedByYear = groupEventsByYear(events)

    const result = Object.keys(eventsGroupedByYear).reduce<CalendarT>(
        (acc, year) => {
            return [
                ...acc,
                {
                    year,
                    months: toCalendarMonths(eventsGroupedByYear[year]),
                },
            ]
        },
        []
    )

    return result
}
