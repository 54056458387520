export const getEvents = (): Promise<unknown[]> => {
    const PUBLIC_KEY = "AIzaSyBui9bltxBAtCZhc1UtkMEU6NBlfRRw4Vk"
    const CALENDAR_ID =
        "zensystem.io_6dpmoo1hslveaflui0b7dgsobc@group.calendar.google.com"

    const url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?${new URLSearchParams(
        {
            timeZone: "Europe/Budapest",
            singleEvents: "true",
            orderBy: "startTime",
            maxResults: "20",
            timeMin: new Date().toISOString(),
            key: PUBLIC_KEY,
        }
    )}`

    return fetch(url)
        .then((response) => response.json())
        .then((data) => data.items)
}
