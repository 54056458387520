import React, { useEffect, useState } from "react"
import Button from "../../../components/OldDesign/Button"
import SectionTitle from "../../../components/OldDesign/SectionTitle"
import ContentContainer from "../../../templates/ContentContainer"
import CalendarImg from "../../../assets/OldDesign/events/calendar-icon.svg"
import { getCalendar } from "./CalendarMIddleware"
import {
    CalendarEventT,
    CalendarMonthT,
    CalendarT,
    CalendarYearT,
} from "./CalendarTypes"
import { URLProvider } from "../../../utils/URLProvider"

const CalendarEvent: React.FC<CalendarEventT> = ({
    day,
    title,
    description,
    link,
}) => {
    return (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="transition-colors bg-old-design-gray w-full border-2 border-transparent hover:bg-[#e0e2ea] hover:border-old-design-blue"
        >
            <div className="flex justify-between items-center -mx-[2px] -mt-[2px]">
                <div className="font-proxima font-bold text-white bg-[linear-gradient(45deg,#0e9de5_0%,#4cdfdd_100%)] h-11 w-11 flex justify-center items-center">
                    {day}
                </div>
                <div className="pr-4 font-proxima text-horizen-dark-blue text-base">
                    Online | English
                </div>
            </div>
            <div className="p-4 pb-11">
                <p className="font-proxima text-horizen-dark-blue text-lg font-bold">
                    {title}
                </p>
                <p
                    className="mt-4 font-proxima text-horizen-dark-blue text-base overflow-hidden [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical]"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
        </a>
    )
}

const CalendarMonth: React.FC<CalendarMonthT> = ({ month, events }) => {
    return (
        <>
            <div className="flex items-center mt-8 mb-6">
                <h3 className="font-proxima text-old-design-dark-blue text-2xl font-bold mr-4">
                    {month}
                </h3>
                <hr className="w-full bg-old-design-gray" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-max mb-12 gap-y-5 gap-x-5">
                {events.map((calendarEvent) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <CalendarEvent key={calendarEvent.id} {...calendarEvent} />
                ))}
            </div>
        </>
    )
}

const CalendarYear: React.FC<CalendarYearT> = ({ year, months }) => {
    return (
        <div>
            <h2 className="mt-20 -mb-5 text-old-design-dark-blue text-4xl font-bold uppercase">
                {year}
            </h2>
            {months.map((calendarMonth) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <CalendarMonth key={calendarMonth.month} {...calendarMonth} />
            ))}
        </div>
    )
}

const Calendar = () => {
    const [calendar, setCalendar] = useState<CalendarT>()

    useEffect(() => {
        getCalendar().then((c) => {
            setCalendar(c)
        })
    }, [])

    return (
        <>
            {calendar?.map((calendarYear) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <CalendarYear key={calendarYear.year} {...calendarYear} />
            ))}
        </>
    )
}

const EventsCalendar = () => {
    return (
        <section className="bg-white py-20">
            <ContentContainer>
                <SectionTitle text="upcoming events" />

                <div className="mt-20">
                    <Button width="fit" href={URLProvider.URL_EVENTS_CALENDAR}>
                        <div className="flex justify-between">
                            <img
                                src={CalendarImg}
                                alt="Calendar"
                                className="h-6 w-6 mr-5"
                            />
                            Add events to your calendar
                        </div>
                    </Button>
                </div>

                <Calendar />
            </ContentContainer>
        </section>
    )
}

export default EventsCalendar
