import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import EventsCalendar from "../sections/OldDesign/events/EventsCalendar"
import EventsHelmet from "../sections/OldDesign/events/EventsHelmet"
import EventsHero from "../sections/OldDesign/events/EventsHero"
import Layout from "../templates/Layout"

const Events = () => {
    return (
        <Layout>
            <div className="bg-horizen-dark-blue">
                <EventsHelmet />
                <EventsHero />
                <EventsCalendar />
                <NewsletterForm />
            </div>
        </Layout>
    )
}

export default Events
