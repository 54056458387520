import React from "react"
import { Helmet } from "react-helmet"

const EventsHelmet = () => {
    return (
        <Helmet>
            <title>Horizen Events</title>
            <meta property="og:title" content="Horizen Events" />
            <meta name="twitter:title" content="Horizen Events" />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Stay up to date on all things Horizen. Join the team every Monday for Horizen Weekly Insider and Q&A."
            />
            <meta
                property="og:description"
                content="Stay up to date on all things Horizen. Join the team every Monday for Horizen Weekly Insider and Q&A."
            />
            <meta
                name="twitter:description"
                content="Stay up to date on all things Horizen. Join the team every Monday for Horizen Weekly Insider and Q&A."
            />
            <meta
                name="keywords"
                content="horizen events, zen events, zencash events, zen cash events"
            />
            <link rel="canonical" href="https://www.horizen.io/events/" />
            <meta property="og:url" content="https://www.horizen.io/events/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
            <script type="application/ld+json" />
        </Helmet>
    )
}

export default EventsHelmet
